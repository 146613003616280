import { GetServerSideProps } from "next";
import { ReactElement } from "react";
import dynamic from "next/dynamic";

import MainBanner from "@components/insureyourcar/mainBanner";

import InsureYourCarWrapper from "@components/layouts/insureYourCarWrapper";
import { getPageProps } from "@services/initial-calls";

const HomeComponentsList = dynamic(
    () => import("@components/insureyourcar/homeComponentsList"),
);

export default function InsureYourCarHome(): ReactElement {
    return (
        <InsureYourCarWrapper>
            <MainBanner />
            <HomeComponentsList />
        </InsureYourCarWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
